<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="fixed">
        <adv-search
          @search="advSearch"
          :is_date_range="true"
          :is_service="true"
          :is_status="true"
          :is_job_report="false"
          :is_location="true"
          :is_admin_sp_location="false"
          :is_payment_status="true"
          :is_location_appointment="true"
          :reportData="this.excelreports"
          :titleworksheet="this.titleworksheet"
          :json_fields="this.json_fields"
          :header="this.header"
          :report_name="'Appointment Report.xls'"
        />
        <!-- <div class="row" v-if="reports.length > 0 && !getLoadingData">
          <div class="col-md-12">
            <download-excel
              class="btn btn-outline-primary ml-1"
              :data="excelreports"
              :header="header"
              :worksheet="search.date_from + ' - ' + search.date_to"
              name="location-job-report.xls"
              :fields="json_fields"
            >
              <i class="test-white fa fa-download"></i>
            </download-excel>
          </div>
        </div> -->
      </div>

      <div
        class="row"
        style="margin-top:4%"
        v-if="reports.length > 0 && !getLoadingData"
      >
        <div class="col-md-12">
          <v-table :columns="columns">
            <tr v-for="(item, index) in reports" :key="index">
              <td>
                {{ ++index }}
              </td>
              <td>
                {{ item.location }}
              </td>
              <td
                v-show="search.location != 'all' && search.location != ''"
                class="text-right"
                v-for="(service_count, index2) in item.service_data"
                :key="index2"
              >
                {{ service_count.count }}
              </td>
              <td class="text-right">{{ item.total }}</td>
            </tr>
            <tr>
              <td :colspan="this.columns.length - 1">
                <b>Grand Total</b>
              </td>
              <td class="text-right">
                <b> {{ total_apps }}</b>
              </td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="reports.length < 1 && !getLoadingData && !load">
        <div class="col-md-12 text-center">
          <h4>no report(s) found</h4>
        </div>
      </div>
      <div class="row" v-if="load" style="opacity: 0.8;">
        <div class="col-md-12 text-center">
          <h4>Please select the criteria</h4>
        </div>
      </div>

      <div class="row move-below" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_SP_SERVICE_APP_REPORT
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import AdvSearch from "@/includes/search/AdvSearch";
// import * as PDF417 from "pdf417-generator";
export default {
  components: {
    vTable,
    vDataLoader,
    AdvSearch
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getSearchDate"]),

    total_apps: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += parseFloat(element.total);
        });
      }
      return total;
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      search: {
        service: "all",
        date_from: "",
        date_to: "",
        location: "all",
        service_status: "all",
        payment_status: "all",
        search_text: ""
      },
      titleworksheet: "",
      getLoadingData: false,
      columns: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Report",
          route: ""
        },
        {
          id: 2,
          title: "Appointment Summary",
          route: ""
        }
      ],
      reports: [],
      header: [],
      load: true,
      excelreports: [],
      json_fields: {}
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },

  methods: {
    advSearch(value) {
      this.getLoadingData = true;
      this.load = false;
      let { header, search } = value;
      this.header = header;
      this.search = { ...search };
      search["sp_id"] = this.search.location;
      this.titleworksheet = search.date_from + " - " + search.date_to;
      //   this.tablereset();

      this.$store
        .dispatch(FETCH_SP_SERVICE_APP_REPORT, search)
        .then(res => {
          if (search.sp_id != "" && search.sp_id != "all") {
            this.json_fields = {};
            this.json_fields["Location"] = "location";
            this.columns = [];
            if (res.data.length > 0) {
              res.columns.map(item => {
                var obj = {};
                obj[item] = item;
                this.json_fields[item] = item;
              });
              this.json_fields["Total"] = "total";

              this.columns.push(
                {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },{
                name: "Location",
                width: "",
                label: "Location",
                class: ""
              });
              res.columns.map(item => {
                this.columns.push({
                  name: item,
                  width: "",
                  label: item,
                  class: "text-right"
                });
              });
              this.columns.push({
                name: "Total",
                width: "",
                label: "Total",
                class: "text-right"
              });

              // this.json_fields = {
              //   Location: "location",
              //   ...this.cols,
              //   Total: "total"
              // };

              // console.log(this.excelreports);
            }
          } else {
            this.json_fields = {
              "Service Provider": "location",
              Total: "total"
            };
            this.columns = [
            {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
              {
                name: "Service Provider",
                width: "",
                label: "Service Provider",
                class: ""
              },
              {
                name: "Total",
                width: "",
                label: "Total",
                class: "text-right"
              }
            ];
          }
          this.getLoadingData = false;
          this.reports = res.data;
          let data = [...res.data];
          var apps = 0;
          if (data.length != 0) {
            data.forEach(element => {
              apps += parseInt(element.total);
            });
          }
          this.excelreports = [...res.data];
          let gtotal = {
            total: apps
          };
          this.excelreports.push(gtotal);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
    },

    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    }
  }
};
</script>
